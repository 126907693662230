<template>
  <section id="platform-settings-bucket">
    <!-- Bucket configuration -->
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("code.Service") }}</h1>
        <h5 class="text-primary">
          {{ $t("modeloPlatf") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-form @submit.prevent="updateMode">
            <b-row class="m-1">
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  label="Modo"
                >
                  <div class="d-flex">
                    <b-form-select
                      v-model="platformMode"
                      :options="typeMode"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="platformMode == 'FRM'"
              class="m-1"
            >
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('timeModeFree')"
                >
                  <div class="d-flex">
                    <b-form-input
                      v-model="timeFreemium"
                      :placeholder="$t('timeIntr')"
                      autocomplete="new-password"
                      class="mr-1"
                      type="number"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-1">
              <b-col>
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('omitirReg')"
                >
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="isSkipable"
                      switch
                    >
                      {{ $t('Omitir registro') }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-1">
              <b-col style="text-align: end;">
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BFormCheckbox,
  BFormInput,
  BForm,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { isEmpty, messageError, showToast } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BCard,
    BFormSelect,
    BButton,
    BFormGroup,
    BForm,
    BFormInput,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      platformMode: 'FRE',
      timeFreemium: null,
      typeMode: {
        FRE: 'Free',
        FRM: 'Freemium',
        SUS: 'Suscription',
      },
      isSkipable: false,
    }
  },

  created() {
    const userData = getUserData()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    axios
      .post('', {
        query: `{
          client(id: "${userData.profile.client.id}") {
            id
            timeFreemium
            platformMode
            isSkipable
          }
        }`,
      })
      .then(r => {
        this.timeFreemium = r.data.data.client.timeFreemium

        this.isSkipable = r.data.data.client.isSkipable
        this.platformMode = r.data.data.client.platformMode
      })
  },
  methods: {
    updateMode() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const userData = getUserData()

      const query = `
              mutation($timeFreemium: Int){
              updateClient(id:"${userData.profile.client.id}",input:{
                timeFreemium:$timeFreemium,
                platformMode:${this.platformMode},
                isSkipable:${this.isSkipable}
              }) {
                client{
                  name
                  id
                }
              }
            }
          `
      data.append('query', query)
      data.append('variables', JSON.stringify({
        timeFreemium: isEmpty(this.timeFreemium) ? null : this.timeFreemium,
      }))

      axios
        .post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },

  },
}
</script>
